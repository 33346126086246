import { FETCH_CONFIGS, FETCH_CAMERA } from './types';

import { getBcapSettings } from './api';

const requestConfig = () => ({ type: FETCH_CONFIGS });
const requestCameraConfig = () => ({ type: FETCH_CAMERA });

const configReceived = (error, cameras) => ({
  type: FETCH_CONFIGS,
  payload: { error, cameras },
});

const cameraConfigReceived = (error, camera) => ({
  type: FETCH_CAMERA,
  payload: { error, camera },
});

export const fetchConfigs = () => (dispatch) => {
  dispatch(requestConfig());

  getBcapSettings().then((data) => {
    dispatch(configReceived(null, data));
  }).catch((err) => {
    dispatch(configReceived(err));
  });
};

export const fetchCamera = (cameraKey) => (dispatch) => {
  dispatch(requestCameraConfig());
  getBcapSettings().then((data) => {
    const camera = data.find((item) => item.cameraKey === cameraKey);
    dispatch(cameraConfigReceived(null, camera));
  }).catch((err) => {
    dispatch(cameraConfigReceived(err));
  });
};

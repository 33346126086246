import {
  FETCH_INFO_LIST,
  SET_INFO_FILTERS,
  SET_INFO_SORT,
  SET_INFO_PAGE_AND_SIZE,
} from './types';
import { displayErrorModal } from '../../../helpers/swal';
import { getInfo } from './apis';

const requestInfoList = (isUpdate) => ({
  type: FETCH_INFO_LIST,
  payload: { isUpdate },
});

const receiveInfoList = (error, data, totalRows) => ({
  type: FETCH_INFO_LIST,
  payload: { error, data, totalRows },
});

export const fetchInfoList = (isUpdate) => async (dispatch, getState) => {
  const {
    info: {
      page,
      pageSize,
      filters,
      sort,
    },
  } = getState();

  dispatch(requestInfoList(isUpdate));

  try {
    const { data, total } = await getInfo(page, pageSize, filters, sort);
    dispatch(receiveInfoList(undefined, data, total));
  } catch (err) {
    console.error(err);
    displayErrorModal();
    dispatch(receiveInfoList(err));
  }
};

export const setFitlters = (rawFilters) => {
  const filters = {};

  Object.entries(rawFilters).forEach(([key, { filterVal }]) => {
    if (typeof filterVal === 'string') {
      filters[key] = filterVal;
    } else {
      console.warn('Unexpected filter type.');
    }
  });

  return {
    type: SET_INFO_FILTERS,
    payload: { filters },
  };
};

export const setSort = ({ sortField, sortOrder }) => ({
  type: SET_INFO_SORT,
  payload: { sortField, sortOrder },
});

export const setPageAndSize = (page, pageSize) => ({
  type: SET_INFO_PAGE_AND_SIZE,
  payload: { page, pageSize },
});

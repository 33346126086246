import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useCallback,
} from 'react';
import classnames from 'classnames';
import { Event } from 'react-socket-io';

import { streamToBase64 } from '../../../helpers/utils';
import { socketEventNames } from '../../../helpers/constants';

import InfoBar from './InfoBar';
import Controls from './Controls';
import SnapshotAnimation from './SnapshotAnimation';
import StreamImage from './StreamImage';

import style from './style.module.scss';

const { MONITOR_LIVESTREAM } = socketEventNames;
const initialStreamState = { image: null, fps: 0 };

const Camera = ({ selected, name: cameraName, controlsEnabled = true }) => {
  const timeoutRef = useRef();
  const [stream, setStream] = useState(initialStreamState);

  const handleLivestream = useCallback((camera, binary, hostName, fps) => {
    if (camera === cameraName) {
      setStream({ image: streamToBase64(binary), fps: fps || 0 });

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => setStream(initialStreamState), 15 * 1000);
    }
  }, [cameraName]);

  useEffect(() => {
    setStream(initialStreamState);
  }, [cameraName]);

  // clear the timeout on unmount
  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  return (
    <>
      <Event event={MONITOR_LIVESTREAM} handler={handleLivestream} />
      <div className={classnames('camera', style.camera, { [style.selected]: selected })}>
        <div className={style.stream}>
          <StreamImage src={stream.image} camKey={cameraName} />
        </div>

        {!stream.image && (
          <div className={style.camPlaceholder}>
            <i className="ion ion-ios-videocam" />
            <h4>CAMERA OFF</h4>
          </div>
        )}
        {controlsEnabled && <InfoBar cameraName={cameraName} fps={stream.fps} />}
        {controlsEnabled && <Controls cameraName={cameraName} />}
        {controlsEnabled && <SnapshotAnimation cameraName={cameraName} />}
      </div>
    </>
  );
};

export default memo(Camera);

import React, { useMemo } from 'react';
import { get } from 'lodash';
import {
  Form,
  Card,
  Accordion,
  OverlayTrigger,
  Tooltip,
  Col,
} from 'react-bootstrap';

import FormInput from '../FormInput';

import style from '../style.module.scss';

const GphotoSettings = ({ camera, register, setValue }) => {
  const formFields = useMemo(() => camera?.gphotoConfigs?.filter((item) => ['RADIO', 'MENU', 'RANGE'].includes(item.widgetType)), [camera.gphotoConfigs]);

  const tableFields = useMemo(() => camera?.gphotoConfigs?.filter((item) => ['TEXT'].includes(item.widgetType)), [camera.gphotoConfigs]);

  if (!camera) {
    return null;
  }

  // This config does not exist always, therefore we set Canon to be a fallback
  const manufacturer = camera.gphotoConfigs.find((item) => item.configKey === 'manufacturer') || { configValue: 'Canon' };
  const cameraManufacturer = manufacturer.configValue.includes('Canon') ? 'canon' : 'sony';

  const { gPhotoSettings } = camera;
  const getDefaultValue = (item) => {
    const settingsValue = get(gPhotoSettings, `${cameraManufacturer}.${item.configKey}`);

    if (['RADIO', 'MENU'].includes(item.widgetType) && !Object.values(item.choices).includes(settingsValue)) {
      return item.configValue;
    }

    return settingsValue || item.configValue;
  };

  const fields = formFields.map((item) => (
    <Form.Group key={`${camera._id}-${item.configKey}`}>
      <Form.Row>
        <OverlayTrigger
          key={`${camera._id}-${item.configKey}-overlay`}
          placement="right"
          overlay={(
            <Tooltip id={`${camera._id}-${item.configKey}-tooltip`}>
              {item.configKey}
            </Tooltip>
          )}
        >
          <Form.Label column md={6}>{item.label}</Form.Label>
        </OverlayTrigger>
        <Col md={6}>
          <FormInput
            setValue={setValue}
            register={register}
            item={item}
            form="gPhotoSettings"
            defaultValue={getDefaultValue(item)}
          />
        </Col>
      </Form.Row>
    </Form.Group>
  ));

  const table = tableFields.map((item) => (
    <div className={style.cameraInfo} key={`${camera._id}-${item.configKey}-container`}>
      <Form.Group md="2" key={`${camera._id}-${item.configKey}`}>
        <Form.Row>
          <OverlayTrigger
            key={`${camera._id}-${item.configKey}-overlay`}
            placement="right"
            overlay={(
              <Tooltip id={`${camera._id}-${item.configKey}-tooltip`}>
                {item.configKey}
              </Tooltip>
            )}
          >
            <Form.Label column md={6} className={style.formSpacing}>{item.label}</Form.Label>
          </OverlayTrigger>
          <Col md={6} className={style.formSpacing}>
            <span>{item.configValue}</span>
          </Col>
        </Form.Row>
      </Form.Group>
    </div>
  ));

  return (
    <div className={style.FormContainer}>
      <Card>
        <Card.Header>
          Gphoto settings
        </Card.Header>
        <Card.Body>
          {fields}
        </Card.Body>
      </Card>
      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Gphoto info
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {table}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default GphotoSettings;

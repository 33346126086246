import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Event } from 'react-socket-io';

import { useTakingSnapshotHook } from '../hooks';
import { socketEventNames } from '../../../../helpers/constants';

import style from './style.module.scss';

const { MONITOR_TAKE_SNAPSHOT, MONITOR_NEW_SNAPSHOT } = socketEventNames;

const SnapshotAnimation = ({
  cameraName,
  numOfSnapshots,
}) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [
    takingSnapshot,
    onTakeSnapshot,
    onNewSnapshot,
  ] = useTakingSnapshotHook(numOfSnapshots, cameraName);

  useEffect(() => {
    if (takingSnapshot && !showAnimation) {
      setShowAnimation(true);
    }
  }, [takingSnapshot]);

  useEffect(() => {
    let timeout;

    if (showAnimation) {
      timeout = setTimeout(() => setShowAnimation(false), 4000);
    }

    return () => clearTimeout(timeout);
  }, [showAnimation]);

  return (
    <>
      <Event event={MONITOR_TAKE_SNAPSHOT} handler={onTakeSnapshot} />
      <Event event={MONITOR_NEW_SNAPSHOT} handler={onNewSnapshot} />
      {showAnimation && (
        <div
          className={style.focusContainer}
          style={{ animationDuration: `${numOfSnapshots * 100}ms`, animationIterationCount: numOfSnapshots }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.27 296.27">
            <path d="M160.76,325.42V259.85a10,10,0,0,1,10-10h65.57" />
            <path d="M375.47,249.85H441a10,10,0,0,1,10,10v65.57" />
            <path d="M451,466.56v65.56a10,10,0,0,1-10,10H375.47" />
            <path d="M236.33,542.12H170.76a10,10,0,0,1-10-10V466.56" />
          </svg>
        </div>
      )}
    </>
  );
};

export default connect(
  ({ monitor: { options: { numOfSnapshots } } }) => ({ numOfSnapshots }),
)(SnapshotAnimation);

import React, { useEffect, useCallback, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { batch, connect } from 'react-redux';
import { Table, Spinner } from 'react-bootstrap';
import { map } from 'lodash';

import { headerFormatter, TextFilter } from '../../../components/ReactBootstrapTable2/Filter';
import Loader from '../../../shared/Loader';

import { useIsLoaded } from '../../../helpers/hooks';
import {
  fetchInfoList,
  setSort,
  setPageAndSize,
  setFitlters,
} from './actions';

const Info = ({
  items,
  isFetching,
  isUpdating,
  error,
  page,
  pageSize,
  totalRows,
  filters,
  sort,
  fetchInfoListAction,
  setSortAction,
  setFiltersAction,
  setPageAndSizeAction,
}) => {
  const isLoaded = useIsLoaded(isFetching);

  const textFilterRenderer = useCallback((onFilter, { dataField }) => (
    <TextFilter
      onFilter={onFilter}
      onSort={(sortOrder) => {
        setSortAction({ sortField: dataField, sortOrder });
        setPageAndSizeAction(0, pageSize);
      }}
    />
  ), [pageSize]);

  const columns = useMemo(() => [{
    text: 'Camera Hotname',
    dataField: 'hostName',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'External IP Address',
    dataField: 'externalIP',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'PTU Model',
    dataField: 'ptuModel',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Pan Step Mode',
    dataField: 'panStepMode',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Pan Speed',
    dataField: 'panSpeed',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Pan Acceleration',
    dataField: 'panAcceleration',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Min Pan Position',
    dataField: 'minPanPosition',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Max Pan Position',
    dataField: 'maxPanPosition',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Tilt Step Mode',
    dataField: 'tiltStepMode',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Tilt Speed',
    dataField: 'tiltSpeed',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Tilt Acceleration',
    dataField: 'tiltAcceleration',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Min Tilt Position',
    dataField: 'minTiltPosition',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }, {
    text: 'Max Tilt Position',
    dataField: 'maxTiltPosition',
    classes: 'align-middle',
    editable: false,
    filter: customFilter(),
    headerFormatter,
    filterRenderer: textFilterRenderer,
  }], [textFilterRenderer]);

  const handleTableChange = (type, {
    page: newPage,
    sizePerPage,
    filters: newFilters,
    sortField,
    sortOrder,
  }) => {
    if (type === 'filter') {
      batch(() => {
        setFiltersAction(newFilters);
        setPageAndSizeAction(newPage - 1, sizePerPage);
      });
    } else if (type === 'pagination') {
      setPageAndSizeAction(newPage - 1, sizePerPage);
    } else if (type === 'sort') {
      batch(() => {
        setSortAction({ sortField, sortOrder });
        setPageAndSizeAction(newPage - 1, sizePerPage);
      });
    }
  };

  useEffect(() => {
    fetchInfoListAction(isLoaded);
  }, [page, pageSize, filters, sort]);

  useEffect(() => {
    const interval = setInterval(() => fetchInfoListAction(true), 30000);
    return () => {
      clearInterval(interval);
    };
  }, [page, pageSize, filters, sort]);

  if (error) {
    return (
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <p>There was an error. Please try again.</p>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <Spinner as="span" animation="border" role="status" aria-hidden="true" />
      </div>
    );
  }

  return (
    <div className="flex-grow-1">
      <div className="layout-navbar px-4 py-3 d-flex shadow-none">
        <h3 className="m-0">Information</h3>
      </div>
      <div className="px-4 pb-3 d-flex">
        <div className="rounded-table-wrapper shadow mb-4">
          <Table className="fixed m-0">
            <thead>
              <tr>
                <td>Camera Information</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-0 border-0">
                  <div>
                    <ToolkitProvider
                      keyField="_id"
                      data={items}
                      columns={columns}
                      bootstrap4
                      search
                      columnToggle
                    >
                      {(props) => (
                        <BootstrapTable
                          {...props.baseProps}
                          remote={{
                            filter: true,
                            pagination: totalRows > 10,
                            sort: true,
                            cellEdit: false,
                          }}
                          wrapperClasses="table-responsive border-0"
                          pagination={totalRows <= 10 ? undefined : paginationFactory({
                            page: page + 1,
                            sizePerPage: pageSize,
                            totalSize: totalRows,
                            showTotal: true,
                            sizePerPageRenderer: ({ options, onSizePerPageChange }) => (
                              <SizePerPageDropdownStandalone
                                sizePerPage={pageSize}
                                sizePerPageList={map(options, 'page')}
                                onSizePerPageChange={onSizePerPageChange}
                                variation="dropup"
                              />
                            ),
                          })}
                          filter={filterFactory()}
                          noDataIndication={() => isLoaded && (
                            <p className="my-3">There are no camera info records.</p>
                          )}
                          onTableChange={handleTableChange}
                        />
                      )}
                    </ToolkitProvider>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>

          {isUpdating && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({
    info: {
      items,
      isFetching,
      isUpdating,
      error,
      page,
      pageSize,
      totalRows,
      filters,
      sort,
    },
  }) => ({
    items,
    isFetching,
    isUpdating,
    error,
    page,
    pageSize,
    totalRows,
    filters,
    sort,
  }),
  {
    fetchInfoListAction: fetchInfoList,
    setSortAction: setSort,
    setFiltersAction: setFitlters,
    setPageAndSizeAction: setPageAndSize,
  },
)(Info);

import {
  FETCH_INFO_LIST,
  SET_INFO_PAGE_AND_SIZE,
  SET_INFO_FILTERS,
  SET_INFO_SORT,
} from './types';

const initialState = {
  isFetching: false,
  isUpdating: false,
  error: undefined,
  page: 1,
  pageSize: 10,
  totalRows: 0,
  items: [],
  filters: {},
  sort: {},
};

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
  case FETCH_INFO_LIST: {
    const {
      isUpdate,
      error,
      data: items,
      totalRows,
    } = payload;

    return {
      ...state,
      isFetching: !items && !error && !isUpdate,
      isUpdating: !items && !error && isUpdate,
      error,
      items: items || state.items,
      totalRows: totalRows !== undefined ? totalRows : state.totalRows,
    };
  }

  case SET_INFO_PAGE_AND_SIZE: {
    const { page, pageSize } = payload;
    return { ...state, page, pageSize };
  }

  case SET_INFO_FILTERS: {
    const { filters } = payload;
    return { ...state, filters };
  }

  case SET_INFO_SORT: {
    const { sortField, sortOrder } = payload;
    return { ...state, sort: { sortField, sortOrder } };
  }

  default: {
    return state;
  }
  }
};

import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Event, SocketContext } from 'react-socket-io';
import { socketEventNames } from '../../../../../helpers/constants';

const { MONITOR_LIVESTREAM } = socketEventNames;

const HostName = ({ camKey, slug }) => {
  const [hostNames, setHostNames] = useState([]);
  const [newHostName, setNewHostName] = useState();
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.emit(
      'join monitor livestream',
      slug,
      camKey,
      (st) => console.log(`joined livestream for camera ${camKey} ${st}`),
    );

    return () => socket.emit(
      'leave livestream',
      camKey,
      (camera) => console.log(`left livestream for camera ${camera}`),
    );
  }, []);

  useEffect(() => {
    if (newHostName && hostNames.indexOf(newHostName) === -1) {
      setHostNames([...hostNames, newHostName]);
    }
  }, [newHostName]);

  const handleLivestream = (camera, binary, hostName) => {
    if (camera === camKey) {
      setNewHostName(hostName);
    }
  };

  return (
    <div className="form-group">
      <Event event={MONITOR_LIVESTREAM} handler={handleLivestream} />
      <div className="form-row">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="form-label col-form-label col-md-6">
          Hostname
        </label>
        <pre className="col-md-6 m-0 d-flex flex-column justify-content-around text-right">
          {hostNames.join('\n')}
        </pre>
      </div>
    </div>
  );
};

export default connect(({ events: { slug } }) => ({ slug }))(HostName);

import React from 'react';
import Papa from 'papaparse';
import classnames from 'classnames';
import { connect, batch } from 'react-redux';
import { Button, FormFile } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import { omit, difference } from 'lodash';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';

import {
  deleteLanguage,
  openAddLanguageModal,
  setLanguages,
  addLanguage,
} from '../actions';

import RoundButton from '../../../../components/RoundButton';
import TextArea from './TextAreaEditor';
import { displayErrorModal } from '../../../../helpers/swal';

import style from './style.module.scss';

const { ExportCSVButton } = CSVExport;


const LanguagesTable = ({
  languages,
  availableLanguages,
  defaultLanguage,
  deleteLanguageAction,
  openAddLanguageModalAction,
  setLanguagesAction,
  addLanguageAction,
}) => {
  const dynamicColumns = availableLanguages.map((lang) => ({
    text: lang,
    dataField: lang,
    sort: false,
    classes: 'hover-edit-background',
    filter: textFilter(),
    headerFormatter: (column, colIndex, { filterElement }) => (
      <div className={style.ColumnHeaderContainer}>
        <div className={style.ColumnHeader}>
          <span>{ column.text }</span>
          <div>
            {(availableLanguages.length > 1 && column.text !== defaultLanguage)
            && (
              <RoundButton size="sm" onClick={() => deleteLanguageAction(column.text)}>
                <FontAwesomeIcon icon={faMinusCircle} />
              </RoundButton>
            )}
          </div>
        </div>
        { filterElement }
      </div>
    ),
    editorRenderer: (editorProps, value, row) => (
      <TextArea {...editorProps} value={value} maxlength={row.html ? null : row.maxLength} />
    ),
  }));

  const columns = [
    {
      text: 'index',
      dataField: 'index',
      sort: true,
      filter: textFilter(),
      classes: classnames('hover-edit-background', style.minWidth),
    },
    {
      text: 'key',
      dataField: 'key',
      hidden: true,
      editable: false,
    },
    {
      text: 'maxLength',
      dataField: 'maxLength',
      hidden: true,
      editable: false,
    },
    {
      text: 'state',
      dataField: 'state',
      sort: true,
      filter: textFilter(),
      editable: false,
      classes: style.minWidth,
    },
    {
      text: 'description',
      dataField: 'description',
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    ...dynamicColumns,
  ];

  const importHandler = async (evt) => {
    Papa.parse(evt.target.files[0], {
      header: true,
      skipEmptyLines: true,
      // eslint-disable-next-line consistent-return
      complete: ({ errors, data }) => {
        if (errors.length > 0) {
          return displayErrorModal({
            html: (
              <>
                <p>Errors:</p>
                {errors.map(({ message, row }) => (
                  <p key={message}>
                    {message}
                    &nbsp;&nbsp;&nbsp;&nbsp;row:&nbsp;
                    {row + 2}
                  </p>
                ))}
              </>
            ),
          });
        }

        const fileLanguages = omit(data[0], ['index', 'key', 'maxLength', 'state', 'description']);
        const fileLanguagesList = Object.keys(fileLanguages);

        const languagesToDelete = difference(availableLanguages, fileLanguagesList);
        const languagesToAdd = difference(fileLanguagesList, availableLanguages);

        batch(() => {
          languagesToDelete.forEach((language) => deleteLanguageAction(language));
          languagesToAdd.forEach((language) => addLanguageAction(language));
          setLanguagesAction(data);
        });
      },
    });
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={languages}
      columns={columns}
      bootstrap4
      search
      exportCSV
    >
      {(props) => (
        <div className={style.container}>
          <div className={style.header}>
            <h3 className="mb-0">Text Settings</h3>
            <div className={style.tableActionsContainer}>
              <div>
                <Button variant="outline-primary" className="rounded-pill" size="sm" onClick={openAddLanguageModalAction}>+ Language</Button>
              </div>
              <div className={style.fileInput}>
                <FormFile
                  as={({ children }) => (
                    <Button variant="outline-primary" className="rounded-pill" size="sm">
                      {children}
                    </Button>
                  )}
                >
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>Import</label>
                  <FormFile.Input accept="text/csv" onChange={importHandler} />
                </FormFile>
              </div>
              <div className="mr-0">
                <ExportCSVButton {...props.csvProps} className="rounded-pill btn btn-outline-primary btn-sm">Export</ExportCSVButton>
              </div>
            </div>
          </div>
          <BootstrapTable
            {...props.baseProps}
            wrapperClasses="table-responsive searchFilter react-bs-table-card"
            classes="card-table border-top"
            filter={filterFactory()}
            cellEdit={cellEditFactory({ mode: 'dbclick', blurToSave: true })}
            keyField="key"
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default connect(
  ({
    languages: { languages, availableLanguages, defaultLanguage },
  }) => ({
    defaultLanguage,
    languages,
    availableLanguages,
  }),
  {
    deleteLanguageAction: deleteLanguage,
    openAddLanguageModalAction: openAddLanguageModal,
    setLanguagesAction: setLanguages,
    addLanguageAction: addLanguage,
  },
)(LanguagesTable);

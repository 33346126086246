import React from 'react';

import Sidenav from '../../vendor/libs/sidenav';
import Authorize from '../../components/Authorize';

import {
  ANALYTICS_READ,
  MONITOR_READ,
  USERS,
  CONFIG,
  TEMPLATE,
  ROLES_READ,
  EVENT_USERS_READ,
  TRIVIA,
  GRAPHICS,
  OVERLAYS,
  SLIDESHOW,
} from '../../components/Authorize/permissions/resources';

import './Sidenav.scss';

const LayoutSidenav = ({ location }) => {
  const isMenuActive = (url) => location.pathname.indexOf(url) === 0;

  return (
    <Sidenav className="layout-sidenav hide-on-print">
      <div className="sidenav-inner">
        <Sidenav.RouterLink to="/" exact icon="fas fa-calendar-day">Calendar</Sidenav.RouterLink>

        <Authorize permissions={MONITOR_READ}>
          <Sidenav.RouterLink to="/monitor" exact icon="fas fa-video">Monitor</Sidenav.RouterLink>
        </Authorize>

        <Sidenav.RouterLink to="/album" exact icon="far fa-images">Album</Sidenav.RouterLink>

        <Authorize or permissions={[USERS, ROLES_READ]}>
          <Sidenav.Menu icon="fas fa-user-cog" linkText="Admin" active={isMenuActive('/admin')} open={isMenuActive('/admin')}>
            <Authorize permissions={USERS}>
              <Sidenav.RouterLink to="/admin/users" exact>Users</Sidenav.RouterLink>
            </Authorize>
            <Authorize permissions={ROLES_READ}>
              <Sidenav.RouterLink to="/admin/roles" exact>Roles</Sidenav.RouterLink>
            </Authorize>
          </Sidenav.Menu>
        </Authorize>

        <Authorize permissions={ANALYTICS_READ}>
          <Sidenav.RouterLink to="/analytics" icon="fas fa-chart-line">Analytics</Sidenav.RouterLink>
        </Authorize>

        <Authorize or permissions={[TEMPLATE, SLIDESHOW, OVERLAYS, GRAPHICS, TRIVIA]}>
          <Sidenav.Menu icon="far fa-clone" linkText="Template" active={isMenuActive('/template')} open={isMenuActive('/template')}>
            <Authorize permissions={TEMPLATE}>
              <Sidenav.RouterLink to="/template/languages">Languages</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/template/colors">Colors</Sidenav.RouterLink>
            </Authorize>
            <Authorize or permissions={[TEMPLATE, OVERLAYS]}>
              <Sidenav.RouterLink to="/template/overlays">Overlays</Sidenav.RouterLink>
            </Authorize>
            <Authorize or permissions={[TEMPLATE, GRAPHICS]}>
              <Sidenav.RouterLink to="/template/graphics">Graphics</Sidenav.RouterLink>
            </Authorize>
            <Authorize permissions={TEMPLATE}>
              <Sidenav.RouterLink to="/template/share">Share</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/template/seatOptions">Seat Options</Sidenav.RouterLink>
              <Sidenav.RouterLink to="/template/geofence">Geofence</Sidenav.RouterLink>
            </Authorize>
            <Authorize or permissions={[TEMPLATE, TRIVIA]}>
              <Sidenav.RouterLink to="/template/trivia">Trivia</Sidenav.RouterLink>
            </Authorize>
            <Authorize or permissions={[TEMPLATE, SLIDESHOW]}>
              <Sidenav.RouterLink to="/template/slideshow">Slideshow</Sidenav.RouterLink>
            </Authorize>
            <Authorize permissions={TEMPLATE}>
              <Sidenav.RouterLink to="/template/export">Export/Import</Sidenav.RouterLink>
            </Authorize>
          </Sidenav.Menu>
        </Authorize>

        <Authorize permissions={EVENT_USERS_READ}>
          <Sidenav.RouterLink to="/eventUsers" exact icon="fas fa-address-book">Event Users</Sidenav.RouterLink>
        </Authorize>

        <Authorize permissions={CONFIG}>
          <Sidenav.Menu icon="fas fa-cog" linkText="Config" active={isMenuActive('/config')} open={isMenuActive('/config')}>
            <Sidenav.RouterLink to="/config/camera">Camera</Sidenav.RouterLink>
            <Sidenav.RouterLink to="/config/mapping">Mapping</Sidenav.RouterLink>
            <Sidenav.RouterLink to="/config/info">Info</Sidenav.RouterLink>
          </Sidenav.Menu>
        </Authorize>

        <Authorize permissions={CONFIG}>
          <Sidenav.RouterLink to="/mapping" exact icon="fas fa-vector-square">Mapping</Sidenav.RouterLink>
        </Authorize>
      </div>
    </Sidenav>
  );
};

export default LayoutSidenav;

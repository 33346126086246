import {
  SET_MONITOR_LAYOUT,
  TOGGLE_SIDEBAR,
  SET_NUM_OF_SNAPSHOTS,
  SET_SELECTED_CAMERA,
} from './types';
import { SET_CAMERA_QUEUE, SET_FIRST_SRS } from '../../store/types/cameras';
import { setFullscreenSnapshot, setListItemsFilterData } from '../../components/Snapshot/LightBox/actions';

export const setLayout = (layout) => ({
  type: SET_MONITOR_LAYOUT,
  payload: layout,
});

export const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR });

export const setLightBoxSnapshot = (snapshotId) => (dispatch) => {
  // TODO: We need to read "currentTab" from state - it will be used to fetch shared/all photos
  dispatch(setFullscreenSnapshot(snapshotId));
  dispatch(setListItemsFilterData({
    filter: {},
    itemsPerPage: 100,
    currentTab: 'all',
    currentPage: 1,
  }));
};

export const setCamQueue = (cam, queue) => ({
  type: SET_CAMERA_QUEUE,
  payload: { [cam]: queue },
});

export const setNumOfSnapshots = (numOfSnapshots) => ({
  type: SET_NUM_OF_SNAPSHOTS,
  payload: numOfSnapshots,
});

export const selectCamera = (camName) => ({
  type: SET_SELECTED_CAMERA,
  payload: camName,
});

export const setFirstSrs = ({ camera, ...srsData }) => ({
  type: SET_FIRST_SRS,
  payload: { [camera]: srsData },
});

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Event } from 'react-socket-io';

import FormComponent from './FormComponent';

import { fetchConfigs, fetchCamera } from './actions';

import style from './style.module.scss';

const CameraSettings = ({
  cameras,
  isLoading,
  error,
  fetchConfigsAction,
  fetchCameraAction,
}) => {
  useEffect(() => {
    fetchConfigsAction();
  }, []);

  const fetchCameraSettings = (camera) => fetchCameraAction(camera);

  const cameraForms = cameras.map((camera) => (<FormComponent camera={camera} key={`form-${camera._id}`} />));

  if (error) {
    return (
      <h3>{error.message}</h3>
    );
  }

  if (!cameras.length || isLoading) {
    return null;
  }

  return (
    <>
      <div className="pageHeader">
        <h4>Camera</h4>
      </div>
      <div className={style.MainContent}>
        <Event event="gphoto_capabilites upload" handler={fetchCameraSettings} />
        {cameraForms}
      </div>
    </>
  );
};

export default connect(
  ({ cameraSettings: { isLoading, cameras, error } }) => ({ isLoading, cameras, error }),
  { fetchConfigsAction: fetchConfigs, fetchCameraAction: fetchCamera },
)(CameraSettings);

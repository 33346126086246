import { buildURL } from '../../../helpers/urls';
import { get } from '../../../helpers/fetch';

// eslint-disable-next-line import/prefer-default-export
export const getInfo = (page, pageSize, filters = {}, { sortField, sortOrder }) => {
  const searchParams = new URLSearchParams({ page, pageSize, ...filters });

  if (sortField && sortOrder) {
    searchParams.set('sortField', sortField);
    searchParams.set('sortOrder', sortOrder);
  }

  const url = buildURL('/info/cameras', `?${searchParams}`);
  return get(url);
};

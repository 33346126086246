import {
  faArrowToBottom,
  faEnvelope,
  faPrint,
  faShare,
  faShareSquare,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faFacebookF,
  faInstagram,
  faLine,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

const socialMediaIcons = {
  facebook: {
    icon: faFacebookF,
  },
  line: {
    icon: faLine,
  },
  print: {
    icon: faPrint,
  },
  twitter: {
    icon: faTwitter,
  },
  share: {
    icon: faShare,
  },
  'share level 2': {
    icon: faShareSquare,
  },
  whatsapp: {
    icon: faWhatsapp,
  },
  instagram: {
    icon: faInstagram,
  },
  download: {
    icon: faArrowToBottom,
  },
  email: {
    icon: faEnvelope,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const getPlatformIcon = (platform) => socialMediaIcons[platform.toLowerCase()].icon;

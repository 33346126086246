import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Socket } from 'react-socket-io';
import { ToastContainer } from 'react-toastify';

import LayoutNavbar from './LayoutNavbar';
import LayoutSidenav from './LayoutSidenav';
import GlobalSocketEvents from './GlobalSocketEvents';
import layoutHelpers from './helpers';
import Alert from '../../components/Alert';
import LightBox from '../../components/Snapshot/LightBox';
import PromotionModal from '../../components/Promotion/Modal';
import Users from '../../pages/Admin/Users';
import Roles from '../../pages/Admin/Roles';
import Calendar from '../../pages/Calendar';
import Album from '../../pages/Album';
import ShareOptions from '../../pages/Template/ShareOptions';
import Geofence from '../../pages/Template/Geofence';
import Languages from '../../pages/Template/Languages';
import Graphics from '../../pages/Template/Graphics';
import EventUsers from '../../pages/EventUsers';
import Monitor from '../../pages/Monitor';
import Snapshot from '../../pages/Snapshot';
import Analytics from '../../pages/Analytics';
import CameraSettings from '../../pages/Config/CameraSettings';
import MappingInfo from '../../pages/Config/MappingInfo';
import CameraInfo from '../../pages/Config/CameraInfo';
import Colors from '../../pages/Template/Colors';
import Trivia from '../../pages/Template/Trivia';
import Overlays from '../../pages/Template/Overlays';
import SeatOptions from '../../pages/Template/SeatOptions';
import Export from '../../pages/Template/Export';
import Slideshow from '../../pages/Template/Slideshow';
import Mapping from '../../pages/Mapping';
import KeyListener from '../../components/KeyListener';
import NotFound from '../NotFound';
import Authorize from '../../components/Authorize';

import { getEventTemplate } from '../../helpers/api';
import { setEventTemplate } from '../../store/actions/events';
import {
  ANALYTICS_READ,
  MONITOR_READ,
  USERS,
  CONFIG,
  ROLES_READ,
  EVENT_USERS_READ,
  TRIVIA,
  GRAPHICS,
  OVERLAYS,
  SLIDESHOW,
  TEMPLATE,
} from '../../components/Authorize/permissions/resources';

import { urls } from '../../helpers/urls';
import { useLogPageViews } from '../../helpers/hooks';
import { fetchRoles } from '../../pages/Admin/Roles/actions';

const { websocket } = urls;

const noPermissionFallback = (
  <div className="d-flex flex-grow-1 justify-content-center align-items-center">
    <h5>Insufficient permissions</h5>
  </div>
);

const Layout = (props) => {
  const {
    isUserLogged,
    isSessionChecked,
    history,
    location,
    setEventTemplateAction,
    fetchRolesAction,
  } = props;

  useLogPageViews();

  useEffect(() => {
    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
    layoutHelpers.setCollapsed(true);

    return () => {
      layoutHelpers.destroy();
    };
  }, []);

  useEffect(() => {
    if (isUserLogged) {
      fetchRolesAction();
    }
  }, [isUserLogged]);

  useEffect(() => {
    if (isSessionChecked && !isUserLogged) {
      history.push('/login');
    }
  }, [isUserLogged, isSessionChecked, history]);

  useEffect(() => {
    // TODO: refactor - move most of the logic to reducer
    const loadTemplateEvent = async () => {
      try {
        const template = await getEventTemplate();
        setEventTemplateAction(template);
      } catch (err) {
        if (err.statusCode !== 404) {
          console.error(err);

          await Alert.fire({
            title: 'Something went wrong',
            html: 'Please click OK to load template again',
            allowOutsideClick: false,
          });

          loadTemplateEvent();
          return;
        }

        // since the API create this on init, this should never happen
        if (err.statusCode === 404) {
          await Alert.fire({
            title: "Template event doesn't exist",
            html: 'Please notify the support team.',
            allowOutsideClick: false,
          });

          history.push('/login');
        }
      }
    };

    loadTemplateEvent();
  }, [setEventTemplateAction, history]);

  const closeSidenav = (e) => {
    e.preventDefault();
    layoutHelpers.setCollapsed(true);
  };

  return (
    <Socket uri={websocket} options={{ transports: ['websocket'] }}>
      <div className="layout-wrapper layout-1">
        <KeyListener />
        <GlobalSocketEvents />
        <div className="layout-inner">
          <LayoutNavbar />

          <div className="layout-container">
            <LayoutSidenav location={location} />

            <div className="layout-content">
              <div className="flex-grow-1 d-flex flex-column">
                <Switch>
                  <Route path="/" exact component={Calendar} />

                  <Route
                    exact
                    path="/admin/users"
                    component={({ ...routeProps }) => (
                      <Authorize permissions={USERS} fallback={noPermissionFallback}>
                        <Users {...routeProps} />
                      </Authorize>
                    )}
                  />
                  <Route
                    exact
                    path="/admin/roles"
                    component={({ ...routeProps }) => (
                      <Authorize permissions={ROLES_READ} fallback={noPermissionFallback}>
                        <Roles {...routeProps} />
                      </Authorize>
                    )}
                  />

                  <Route path="/album/" exact component={Album} />

                  <Route
                    path="/template/colors"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={TEMPLATE} fallback={noPermissionFallback}>
                        <Colors {...routeProps} />
                      </Authorize>
                    )}
                  />
                  <Route
                    path="/template/share"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={TEMPLATE} fallback={noPermissionFallback}>
                        <ShareOptions {...routeProps} />
                      </Authorize>
                    )}
                  />
                  <Route
                    path="/template/geofence"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={TEMPLATE} fallback={noPermissionFallback}>
                        <Geofence {...routeProps} />
                      </Authorize>
                    )}
                  />
                  <Route
                    path="/template/Languages"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={TEMPLATE} fallback={noPermissionFallback}>
                        <Languages {...routeProps} />
                      </Authorize>
                    )}
                  />
                  <Route
                    path="/template/trivia"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize
                        or
                        permissions={[TRIVIA, TEMPLATE]}
                        fallback={noPermissionFallback}
                      >
                        <Trivia {...routeProps} />
                      </Authorize>
                    )}
                  />
                  <Route
                    path="/template/graphics"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize
                        or
                        permissions={[GRAPHICS, TEMPLATE]}
                        fallback={noPermissionFallback}
                      >
                        <Graphics {...routeProps} />
                      </Authorize>
                    )}
                  />
                  <Route
                    path="/template/overlays"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize
                        or
                        permissions={[OVERLAYS, TEMPLATE]}
                        fallback={noPermissionFallback}
                      >
                        <Overlays {...routeProps} />
                      </Authorize>
                    )}
                  />
                  <Route path="/template/export" exact component={Export} />
                  <Route
                    path="/template/seatOptions"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={TEMPLATE} fallback={noPermissionFallback}>
                        <SeatOptions {...routeProps} />
                      </Authorize>
                    )}
                  />

                  <Route
                    path="/template/slideshow"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize
                        or
                        permissions={[SLIDESHOW, TEMPLATE]}
                        fallback={noPermissionFallback}
                      >
                        <Slideshow {...routeProps} />
                      </Authorize>
                    )}
                  />

                  <Route
                    path="/eventUsers/"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={EVENT_USERS_READ} fallback={noPermissionFallback}>
                        <EventUsers {...routeProps} />
                      </Authorize>
                    )}
                  />

                  <Route
                    exact
                    path="/monitor/"
                    component={({ ...routeProps }) => (
                      <Authorize permissions={MONITOR_READ} fallback={noPermissionFallback}>
                        <Monitor {...routeProps} />
                      </Authorize>
                    )}
                  />

                  <Route path="/snapshot/:snapshotId" exact component={Snapshot} />

                  <Route
                    path="/analytics/:eventId?"
                    component={({ ...routeProps }) => (
                      <Authorize permissions={ANALYTICS_READ} fallback={noPermissionFallback}>
                        <Analytics {...routeProps} />
                      </Authorize>
                    )}
                  />

                  <Route
                    path="/config/camera/"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={CONFIG} fallback={noPermissionFallback}>
                        <CameraSettings {...routeProps} />
                      </Authorize>
                    )}
                  />
                  <Route
                    path="/config/mapping/"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={CONFIG} fallback={noPermissionFallback}>
                        <MappingInfo {...routeProps} />
                      </Authorize>
                    )}
                  />

                  <Route
                    path="/config/info/"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={CONFIG} fallback={noPermissionFallback}>
                        <CameraInfo {...routeProps} />
                      </Authorize>
                    )}
                  />

                  <Route
                    path="/mapping"
                    exact
                    component={({ ...routeProps }) => (
                      <Authorize permissions={CONFIG} fallback={noPermissionFallback}>
                        <Mapping {...routeProps} />
                      </Authorize>
                    )}
                  />

                  <Route component={NotFound} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="layout-overlay" onClick={closeSidenav} onKeyDown={closeSidenav} />

        <LightBox />
        <PromotionModal />
        <ToastContainer enableMultiContainer containerId="suspicious" position="bottom-right" autoClose={5000} />
      </div>
    </Socket>
  );
};

export default connect(
  ({ authentication }) => ({
    isUserLogged: authentication.logged,
    isSessionChecked: authentication.sessionChecked,
  }),
  {
    setEventTemplateAction: setEventTemplate,
    fetchRolesAction: fetchRoles,
  },
)(Layout);

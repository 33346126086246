import { get, post } from '../../../helpers/fetch';

import { buildURL } from '../../../helpers/urls';

export const getBcapSettings = () => {
  const url = buildURL('cameras/configs');
  return get(url);
};

export const updateBcapSettings = (camera, cameraSettings) => {
  const url = buildURL(`cameras/configs/${camera}`);
  return post(url, cameraSettings);
};
